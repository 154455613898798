/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: #f2f6fd;
}

.header {
  background: white;
  border-bottom: 0.5px solid #3f51b5 !important;
}

.octopus-sidenav {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 10rem;
  padding: 1rem;
  background: #f2f6fd;
}

.octopus-card {
  margin: 1rem;
}

.octopus-main-card {
  border: 0.5px solid #3f51b5 !important;
}

.octopus-card-anchor-button {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.octopus-nav-button {
  margin-left: 0.5rem !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.octopus-sidenav-content {
  background: #f2f6fd;
}


.mat-drawer-inner-container {
  background: white;
  border: 0.5px solid #3f51b5 !important;
  margin-bottom: -1px;
  border-radius: 0.2rem;
}

.mat-drawer-side {
  border-right: none !important;
}


.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;

  .mat-table {
    width: 100%;
    max-width: 100%;
    display: table;
    border-collapse: collapse;
    margin: 0;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
  }

  .mat-cell,
  .mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 2px;
    line-break: unset;
    width: auto;
    white-space: normal;
    overflow: hidden;
    vertical-align: middle;
  }

  .mat-column-actions {
    word-wrap: break-word !important;
    white-space: nowrap !important;
    overflow-wrap: break-word;
    word-wrap: break-word;

    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}
